<template>
  <div class="wrapper wrapper-full-page">
    <div class="page-header login-page header-filter">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-7 ml-auto mr-auto">
            <form class="form" @submit.prevent="submitForm">
              <div class="card card-login">
                <div class="card-header card-header-primary text-center">
                  <h4 class="card-title">
                    {{ $t("auth.reset.desc_reset_password") }}
                  </h4>
                </div>
                <div class="card-body">
                  <div class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="material-icons">email</i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        type="email"
                        placeholder="Email"
                        @input="updateEmail"
                        @focus="focusField('email')"
                        @blur="clearFocus"
                        required
                        autocomplete="email"
                        autofocus
                      />
                    </div>
                  </div>
                  <div class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="material-icons">password</i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        type="password"
                        :placeholder="$t('auth.reset.new_password')"
                        @input="updatePassword"
                        @focus="focusField('password')"
                        @blur="clearFocus"
                        autocomplete="new-password"
                        required
                      />
                    </div>
                  </div>
                  <div class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="material-icons">check</i>
                        </span>
                      </div>
                      <input
                        class="form-control"
                        type="password"
                        :placeholder="$t('auth.reset.new_password_confirmation')"
                        @input="updatePasswordConfirmation"
                        @focus="focusField('password_confirmation')"
                        @blur="clearFocus"
                        autocomplete="new-password-confirm"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer justify-content-center flex-column">
                  <vue-button-spinner
                    class="btn btn-link btn-primary"
                    type="submit"
                    :status="status"
                    :isLoading="loading"
                    :disabled="loading"
                    @submit="submitForm"
                  >
                    {{ $t("auth.reset.do_reset_password") }}
                  </vue-button-spinner>
                  <bootstrap-alert />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      status: "",
      activeField: ""
    };
  },
  computed: {
    ...mapGetters("ResetPassword", ["loading"])
  },
  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions("ResetPassword", [
      "resetPassword",
      "setEmail",
      "setPassword",
      "setPasswordConfirmation",
      "setToken",
      "setLoading",
      "resetState"
    ]),
    updateEmail(e) {
      this.setEmail(e.target.value);
    },
    updatePassword(e) {
      this.setPassword(e.target.value);
    },
    updatePasswordConfirmation(e) {
      this.setPasswordConfirmation(e.target.value);
    },
    submitForm() {
      let queryParams = new URLSearchParams(window.location.search);
      this.setToken(queryParams.get('token'));
      this.resetPassword()
        .then(() => {
          this.setLoading(true);
          this.status = "success";
          _.delay(() => {
            this.$router.push('login')
          }, 2000)
          this.$eventHub.$emit("send-email-success");
        })
        .catch(error => {
          this.status = "failed";
          _.delay(() => {
            this.status = "";
            this.setLoading(false);
          }, 3000);
        });
    },
    focusField(name) {
      this.activeField = name;
    },
    clearFocus() {
      this.activeField = "";
    }
  }
};
</script>